//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

//  ================================================================================================
//  File Name: variables.scss
//  Description: Custom component variable
//  ================================================================================================

$base-font-size: 14px !default;
$body-direction: ltr !default; // Default ltr, change it to rtl for Right To Left support.
$content-padding: 2rem !default;

//  ------------------------------
//    Colors
//  ------------------------------
$nav-component-border-color: #ddd !default;

$swiper-bg: #f2f4f4 !default;

//  ------------------------------
//    Navbar
//  ------------------------------
$navbar-height: 4.45rem !default;
$horizontal-menu-height: 4.45rem !default;
$footer-height: 3.35rem !default;

//  ------------------------------
//    Main Menu
//  ------------------------------

//main menu dark
$menu-dark-color: #dcdcdc !default;
$menu-dark-bg-color: #10163a !default;

$menu-padding: 10px 15px 10px 15px !default;
$menu-second-level-padding: 10px 15px 10px 20px !default;
$menu-third-level-padding: 10px 15px 10px 53px !default;
$menu-forth-level-padding: 10px 15px 10px 53px !default;

// vertical menu
$menu-expanded-width: 260px !default;
$menu-collapsed-width: 80px !default;

//  ------------------------------
//    Sidebar
//  -------------------------------
$sidebar-width: 260px !default;
$chat-sidebar-width: 360px !default;

//  -------------------------------
//    Avatar
//  -------------------------------

$avatar-size: 32px !default;
$avatar-status-size: 11px !default;
$avatar-status-size-lg: 17px !default;

$avatar-size-xl: 70px !default;
$avatar-size-lg: 50px !default;
$avatar-size-sm: 24px !default;

$avatar-bg: #c3c3c3;
$avatar-group-border: $white;

//  -------------------------------
//  Blank Page Bg Color
//  -------------------------------

$blank-bg-color: #eff2f7 !default;

//  -------------------------------
//  Data Tables Bg Color
//  -------------------------------
$datatable-bg-color: #f8f8f8 !default;

//  -------------------------------
//  Timeline
//  -------------------------------
$timeline-border-color: $border-color !default;

$timeline-point-size: 1.75rem !default;
$timeline-point-indicator-size: 12px !default;
$timeline-point-indicator-color: $primary !default;
$timeline-point-indicator-wrapper-size: 20px !default;

$timeline-item-min-height: 4rem !default;
$timeline-item-icon-font-size: 0.85rem !default;

$timeline-event-time-size: 0.85rem !default;
$timeline-event-time-color: $text-muted !default;

// ------------------------------------------------
// custom options
// ------------------------------------------------
$custom-options-bg-color: rgba($secondary, 0.06);

//  ================================================================================================
//  File Name: variables-dark.scss
//  Description: Custom dark theme variables
//  ================================================================================================
//Body
$theme-dark-body-bg: #161d31 !default;
$theme-dark-body-color: #b4b7bd !default;
$theme-dark-border-color: #3b4253 !default;
$theme-dark-custom-control-border-color: #44405e !default;

//Typography
$theme-dark-headings-color: #d0d2d6 !default;
$theme-dark-label-color: #d0d2d6 !default;
$theme-dark-text-muted-color: #676d7d !default;

//Card
$theme-dark-card-bg: #283046 !default;
$theme-dark-box-shadow: 0 4px 24px 0 rgba($black, 0.24);

//Input
$theme-dark-input-bg: #283046 !default;
$theme-dark-input-placeholder-color: #676d7d !default;
$theme-dark-input-border-color: #404656 !default;
$theme-dark-input-disabled-bg: #24233a !default;
$theme-dark-input-disabled-border-color: #444b60 !default;

// Switch
$theme-dark-switch-bg: #545a6a;
$theme-dark-switch-bg-disabled: #1b2337;

//Table
$theme-dark-table-bg: #283046 !default;
$theme-dark-table-header-bg: #343d55 !default;
$theme-dark-table-row-bg: #283046 !default;
$theme-dark-table-hover-bg: #242b3d !default;
$theme-dark-table-striped-bg: #242b3d !default;

$theme-dark-modal-header-bg: #161d31 !default;
$theme-dark-pagination-bg: #242b3d !default;
$theme-dark-chart-bg: #384056 !default;
$theme-dark-widget-bg: #384056 !default;
