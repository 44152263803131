@import "src/assets/scss/variables/variables";
@import "src/assets/scss/variables/variables-components";

.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  &--bg {
    background-color: $theme-dark-body-bg;
  }
}
